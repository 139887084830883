import React from "react";
import { Link } from "react-router-dom";
import Carousel from "../../../components/Carousel/carousel";
import styles from "./ift.module.css";
import Cards from "./cards";
import Parallax from "./parallax";
import Whatsapplink from '../../../components/whatsapp/link';
import styles2 from '../../Home/home.module.css'


import bg from './assets/bg.png';
import ass from './assets/ass.jpeg';
// import auv from './assets/auvinet1.jpg';
import i1 from "./assets/circuit.jpg";
import i2 from "./assets/gopro.jpg";
import i3 from "./assets/ift_collage.jpg";
import i4 from "./assets/ift_collage3.jpg";
import i5 from "./assets/professional_presentor.jpg";
import i6 from "./assets/three.jpg";

import aboutus from './assets/aboutus.png'
import pastDrivers from './assets/pastDrivers.png'
import resources from './assets/resources.png'
import iaw from './assets/iaw.png'
import contactus from './assets/contactus.png'

import robert from './assets/Robert.png'
import joao from './assets/joao.png';
import rayan from './assets/rayan.png';
import patrick from './assets/patrick.png'


const IFT = () => {
  const dataPastD = [
    {
      name: "Robert Battle Sanchez",
      img: robert,
      rank: 6,
      desc: "12 time Spanish Nitro Buggy National Champion and unarguably one of the best RC drivers in world",
    },
    {
      name: "João Figueiredo",
      img: joao,
      rank: 17,
      desc: "And current portugese buggy champion",
    },
    {
      name: "Rayan Medjoubi",
      img: rayan,
      rank: 49,
      desc: "Former french champion",
    },
    {
      name: "Patrick jr Auvinet",
      img: patrick,
      // rank: 18,
      desc: "Former France champion in 1:6 category",
    },
  ];

  const PastDrivers = ({ data }) => {
    return (
      <>
        <div className={`col-md-3 col-sm-6 col-12 ${styles.PastDC}`}>
          <div
            className={styles.pastCards}
            style={{
              backgroundImage: `url(${data.img})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className={styles.infoS}>
              <h4>{data.name}</h4>
              {data.rank && (<p>
                World Rank {data.rank}
                <br />
                {data.desc}
              </p>)}
            </div>
          </div>
        </div>
      </>
    );
  };

  const Border = ({ text }) => {
    return (
      <>
        <div className={styles.titSpn_rect1}>
          <div className={styles.titSpn_rect2}>
            <h2>{text}</h2>
          </div>
        </div>
      </>
    );
  };

  const bgstyles = {
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
    paddingBottom: '30px',
  };

  const walink1 = "https://chat.whatsapp.com/I0TrSN7XDsB0FzQT8CqCFL";
  const dslink1 = "https://chat.whatsapp.com/KPIY7EUqnSMIDSvv2kyn0R";

  return (
    <div style={bgstyles} className={styles.body}>

      {/* heading */}
      <p className={styles.topic}>International Full Throttle</p>

      {/* regCards */}
      <div className={styles.regCards}>
        <Cards className={styles.regCard} />
      </div>

      {/* <Parallax /> */}

      {/* AboutUs */}
      <img src={aboutus} alt="aboutus" className={styles.subHeading} />
      <div className={styles2.vid_desc_container} style={{ marginTop: "-3vh", marginBottom: "-5vh" }}>
        <div>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/Vu9titeNUGA?si=GBpGJtaH4XNQnfEa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" className={styles2.video}  allowFullScreen></iframe>
        </div>
        <div className={styles.desc}>Started a decade back as full throttle, a national-level RC nitro
          buggy competition has now grown into India's largest RC nitro buggy
          racing event. In 2018 took off to the international level with a grand
          success. Brace yourselves as raging nitro buggies battle to dominate
          the full-throttle circuit in India’s Largest RC nitro buggy racing
          event at IIT Bombay during Techfest.</div>
      </div>

      {/* Resources */}
      <img src={resources} alt="resources" className={styles.subHeading} />
      <p style={{color: "wheat"}}>Coming Soon !!!</p>
      {/* <div className={styles.grouplink} style={{paddingBottom : "3vw"}}>
        <div className={styles.wabutton }><Whatsapplink walink={walink1} dslink = {dslink1} target="_blank"/></div>
      </div> */}

      {/* past drivers */}
      <img src={pastDrivers} alt="pastdrivers" className={styles.subHeading} />
      <div className={styles.pastDrivers}>
        <div className={styles.box}>
          <img src={robert} alt="robert"/>
          <h5>Robert Battle Sanchez</h5>
          <p>
            World Rank 6 <br />
            12 time Spanish Nitro Buggy National Champion and unarguably one of the best RC drivers in world
          </p>
        </div>
        <div className={styles.box}>
          <img src={joao} alt="joao" />
          <h5>João Figueiredo</h5>
          <p>
            World Rank 17 <br />
            And current portugese buggy champion
          </p>
        </div><div className={styles.box}>
          <img src={rayan} alt="rayan" />
          <h5>Rayan Medjoubi</h5>
          <p>
            World Rank 49 <br />
            Former french champion
          </p>
        </div><div className={styles.box}>
          <img src={patrick} alt="patrick" />
          <h5>Patrick jr Auvinet</h5>
          <p>
            {/* World Rank 6 <br /> */}
            Former France champion in 1:6 category
          </p>
        </div>
      </div>
      {/* <div className="container">
          <div className="row">
            {dataPastD.map((summit, index) => (
              <PastDrivers key={index} data={summit} />
            ))}
          </div>
        </div> */}

      {/* in ass with */}
      <img src={iaw} alt="iaw" className={styles.subHeading} />
      <div className={styles.iaw}>
        <a href="https://mrchobbies.in/" target="_blank"><img src={ass} alt='MRC' /></a>
      </div>

      {/* contact us */}
      <img src={contactus} alt="contactus" className={styles.subHeading} />
      <div className={styles.contactus}>
        {/* Contactbox1 */}
        <div className={styles.contact1}>
          Mrutyunjaya Sahu<br></br>
          mrutyunjaya@techfest.org<br></br>
          {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
          <div onClick={() => (window.location.href = `tel:${7317520804}`)}>
            {9692376527}
          </div>
        </div>
        <div className={styles.contact2}>
          <div className={styles.forTechIssue}>For Technical Issues :</div> <br />
          Omkar Kashelkar <br />
          MRC <br />
          7784093093
        </div>
      </div>
    </div>
  );
};

export default IFT;
