import React, { useState, useEffect } from 'react';
import './smp.css';
import bg from './Exhi_back.jpeg';

const sponsorLogos = [
    { name: "Sponsor 1", url: "#", img: "https://via.placeholder.com/150" },
    { name: "Sponsor 2", url: "#", img: "https://via.placeholder.com/150" },
    { name: "Sponsor 3", url: "#", img: "https://via.placeholder.com/150" },
    { name: "Sponsor 4", url: "#", img: "https://via.placeholder.com/150" },
    { name: "Sponsor 5", url: "#", img: "https://via.placeholder.com/150" },
    { name: "Sponsor 6", url: "#", img: "https://via.placeholder.com/150" },
    { name: "Sponsor 7", url: "#", img: "https://via.placeholder.com/150" },
    { name: "Sponsor 8", url: "#", img: "https://via.placeholder.com/150" },
];

const Smp = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const bgHaiJi = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        width: "100vw",
        // minHeight: "100vh",
        position: "absolute",
        top: "0",
        zIndex: "-1",
        backgroundAttachment: isMobile ? "scroll" : "fixed", /* Conditional scroll effect */
    };

    return (
        <div>
            {/* Background */}
            <div style={bgHaiJi}>

            {/* Sponsor Section */}
            <section className="sponsor-section">
                <h1 className="sponsor-title">Our Social Media Partners</h1>
                <div className="sponsor-grid">
                    {sponsorLogos.map((sponsor, index) => (
                        <a href={sponsor.url} key={index} className="sponsor-item">
                            <img src={sponsor.img} alt={sponsor.name} className="sponsor-logo" />
                            <p>{sponsor.name}</p>
                        </a>
                    ))}
                </div>
            </section>
            <div className= "Contactbox">
            <div className="Contactbox1">
              Divyansh Ranjan<br></br>
              ranjan@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${9619678850}`)}>
                {8318806303}
              </div>
            </div>
            </div>
            </div>
        </div>
    );
};

export default Smp;
