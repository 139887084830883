import React from 'react'
import styles from './socialinit.module.css'
import img1 from './assets/img1.jpg'
import image1 from './assets/image1.jpg'
import image2 from './assets/image2.jpg'
import image3 from './assets/image3.jpg'
import rclub from './assets/rclub.png'
import tf from './assets/tf.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRightLong} from '@fortawesome/free-solid-svg-icons'

const SocialInit = () => {

  const pdfUrl = "https://drive.google.com/file/d/1NGudWgSJLK4yOV3t8TG4EIdg9KSJD46R/view?usp=sharing";

  return (
    <div className={styles.body}>
      <div className={styles.heroPage}>
        {/* <img src={hero} alt="oiii" className={styles.heroImg} /> */}
        <div className={styles.association}>
          <img src={tf} alt="tf" />
          <div style={{ fontSize: "4vh", fontFamily: "serif" }}>X</div>
          <img src={rclub} alt="rclub" />
        </div>

        <h1 className={styles.heading} >LEGAL LITERACY <br />TRAINING PROGRAM</h1>
        <p className={styles.credit}>BY TECHFEST, IIT BOMBAY <br /> in association with <br /> Rotaract Club of Bombay Handing Garden</p>
      </div>
      <div className={styles.contentPage}>

        {/* vision */}
        <h2 className={styles.subHeading}>VISION</h2>

        {/* grp1 */}
        <div className={styles.grp1}>
          <div className={styles.grp1Left}>
            <img src={img1} alt="" />
          </div>
          <div className={styles.grp1Right}>
            <div className={styles.point}>
              <div className={styles.box}>EMPOWER</div>
              <p>Equip participants with the tools to make informed decisions and navigate legal matters confidently.</p>
            </div>
            <div className={styles.point}>
              <div className={styles.box}>ENGAGE</div>
              <p>Foster a proactive approach to legal awareness through interactive sessions with legal experts.</p>
            </div>
            <div className={styles.point}>
              <div className={styles.box}>ENHANCE</div>
              <p>Promote a deeper understanding of legal frameworks that impact students' personal and professional lives.</p>
            </div>
            <div className={styles.point}>
              <div className={styles.box}>EDUCATE</div>
              <p>Provide students with essential knowledge about common laws and their rights and responsibilities in everyday situations.</p>
            </div>
          </div>
        </div>


        {/* why this incentives */}
        <h2 className={styles.subHeading}>WHY THIS INITIATIVE ?</h2>
        <p style={{ marginBottom: "5vh" }}>1. In 2021, there were approximately 31,170 cases registered under the Indian Penal Code (IPC) against juveniles. Many of these cases were attributed to a lack of awareness about the legal consequences of their actions. <i style={{ fontSize: "10px" }}>( Source: National Crime Records Bureau (NCRB) 2021 report )
        </i> <br />
          2. The NCRB reported over 32,000 cases of cybercrime in 2021, with a significant portion involving young adults. Common cybercrimes include hacking, identity theft, and online harassment, often stemming from ignorance about the legality of certain online activities. <br />
          3. A survey by the Ministry of Social Justice and Empowerment found that nearly 14.6% of students in the age group of 10-17 have experimented with drugs. Awareness about the legal repercussions under the Narcotic Drugs and Psychotropic Substances (NDPS) Act is crucial. <br />
          4. The NCRB recorded over 28,000 cases of sexual harassment in 2021. Educating students about laws such as the Protection of Children from Sexual Offences (POCSO) Act and the Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act can foster safer environments.
        </p>

        {/* the plan */}
        <h2 className={styles.subHeading}>Legal Literacy Training Program</h2>

        <div className={styles.plan}>
          <div className={styles.top}>
            <div className={styles.topLeft}>
              <h1 className={styles.planHead}>THE PLAN</h1>
              <div className={styles.box} style={{ width: "fit-content", marginLeft: "0" }}>COLLABORATE WITH COLLEGES</div>
              <ul>
                <li className={styles.listitems} >Utilise Techfest’s extensive reach of 2500+ colleges and collaborate with them.</li>
                <li className={styles.listitems} >Coordinate with college officials to secure venues and dates for the sessions.</li>
              </ul>
            </div>
            <div className={styles.topRight}>
              <img src={image1} alt="" style={{ width: "100%" }} />
            </div>
          </div>

          <div className={styles.bottom}>
            <div className={styles.bottomLeft}>
              <div className={styles.box} style={{ width: "fit-content", marginLeft: "0" }}>ENGAGE LEGAL EXPERTS</div>
              <ul>
                <li className={styles.listitems} >Bring in legal professionals, advocates, and experts to lead engaging discussions on relevant laws and rights.
                </li>
                <li className={styles.listitems} >Offer practical insights on how students can apply legal knowledge in everyday situations.</li>
              </ul>
              <div className={styles.box} style={{ width: "fit-content", marginLeft: "0" }}>INTERACTIVE LEARNING</div>
              <ul>
                <li className={styles.listitems} >Incorporate Q&A sessions, case studies, and scenario-based activities to foster student participation.
                </li>
                <li className={styles.listitems} >Provide resources for further learning, including handouts and access to legal aid services if needed.
                </li>
              </ul>
            </div>
            <div className={styles.bottomRight}>
              <img src={image2} alt="image2" style={{ marginTop: "2vw", width: "48%" }} />
              <img src={image3} alt="image2" style={{ marginTop: "2vw", width: "50%" }} />
            </div>
          </div>
        </div>

        {/* incentives */}
        <h2 className={styles.subHeading} style={{ marginTop: "10vh" }}>INCENTIVES</h2>
        <p>1. Certification from Techfest, IIT Bombay and Rotary Club of Bombay Hanging Garden<br />
          2. Connect and network with Legal experts<br />
          3. Safeguard yourself against instances of legal emergencies</p>

        <a  href={pdfUrl} target="_blank" rel="noopener noreferrer" className={styles.exploreAnchor}>
          <div className={styles.explorebtn}>
            EXPLORE
            <FontAwesomeIcon icon={faArrowRightLong} className={styles.exploreIcon} />
          </div>
        </a>

        {/* sponsor */}
        <h2 className={styles.subHeading} style={{ marginTop: "10vh" }}>OUR PARTNERS</h2>
        <img src={rclub} alt="sponsor" style={{ width: "30vw" }} />

      </div>


      {/* contact us page */}
      <h2 className={styles.subHeading} style={{ marginTop: "10vh" }}>For Further Help</h2>
      <div className={styles.contactPage}>
        <p style={{ fontSize: "25px" }}>Arman Khatri</p>
        <p>+91 78629 32313</p>
        <p>arman@techfest.org</p>
      </div>

    </div>
  )
}

export default SocialInit
