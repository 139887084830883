import React from 'react';
import styles from './SecyCard.module.css'

const SecyCard = ({Post, Name, Email, phn, img}) => {
    return (
        <>
         <div className={styles.Card}>
            <img className={styles.img} src={img}></img>
            <div className={styles.text}>
              <div className={styles.Post}>{Post}</div>
              <div className={styles.Name}>{Name}</div>
              <div className={styles.Email}>{Email}</div>
              <div className={styles.phn}>{phn}</div>
            </div>
         </div>
           
        </>
    )
}

export default SecyCard