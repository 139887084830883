import React,{useState} from 'react';
import axios from 'axios';
import styles from './summits.module.css';
import SummitCard from './SummitCard';
import bg from './assets/vmcc.jpg';
import bleft from './assets/banner-asset-1.svg';
import bright from './assets/banner-asset-2.svg';
import aakash from './assets/aakashanand.png';
import anuj from './assets/anuj.png';
import zaheer from './assets/zaheer.png';
import sneha from './assets/sneha.png';
import sumit from './assets/sumit.png';
import deepak from './assets/deepak.png';
import haridip from './assets/haridip1.png';
import sid from './assets/sid1.png';
import deepika from './assets/deepika.png';
import sunita from './assets/Sunita-Photoroom.png';
import wadhwani_logo from './assets/Wadwani_logo.jpeg';
import karan from './assets/Karan-Photoroom.png';
import antler_logo from './assets/antler_logo.jpeg';
import nus from  './assets/nus.png';
import i0 from './images/mmc.jpg';
import i01 from './images/google.jpg';
import i1 from './images/i1.jpeg';
import i2 from './images/i2.jpeg';
import i3 from './images/i3.jpeg';
import i4 from './images/i4.jpeg';
import i5 from './images/i5.png';
import i6 from './images/i6.jpeg';
import i7 from './images/i7.jpeg';
import i8 from './images/i8.jpeg';
import coin from './images/coin2.jpg';
import styles2 from '../Compi/compi.module.css';


const speakersData = [
    {
        name: "Sunita Singh",
        position: "Executive Vice President",
        company: "Wadhwani Foundation",
        linkedin: "https://www.linkedin.com/in/sunitaashoksingh/",
        desc: "Deepak Wadhwa is a prominent leader at TRADERsloop. Holding the position of Founder & CEO, he brings significant expertise and insights to his field.",
        img: sunita,
        logo: wadhwani_logo,
        
    },
    
    {
        name: "Aakash Anand",
        position: "Founder",
        company: "Bella Vita",
        linkedin: "https://www.linkedin.com/in/aakashanand/",
        desc: "Aakash Anand is a prominent leader at Bella Vita. Holding the position of Founder, he brings significant expertise and insights to his field.",
        img: aakash,
        logo:i7,
        
    },
    {
        name: "Sumit Gupta",
        position: "Co-Founder",
        company: "CoinDCX",
        linkedin: "https://www.linkedin.com/in/smtgpt/",
        desc: "Sumit Gupta is a prominent leader at CoinDCX. Holding the position of Co-Founder, he brings significant expertise and insights to his field.",
        img: sumit,
        logo: coin,
    },
    {
        name: "Siddharth Dungarwal",
        position: "CEO & Founder",
        company: "Snitch",
        linkedin: "https://www.linkedin.com/in/siddharthrdungarwal/",
        desc: "Siddharth Dungarwal is a prominent leader at Snitch. Holding the position of CEO & Founder, he brings significant expertise and insights to his field.",
        img: sid,
        logo:i6,
    },
    {
        name: "Sneha Basu Roy",
        position: "VC and Partnerships",
        company: "Google India",
        linkedin: "https://www.linkedin.com/in/sneha-basu-roy-772b5253/",
        desc: "Sneha Basu Roy is a prominent leader at Google India. Holding the position of VC and Partnerships, she brings significant expertise and insights to her field.",
        img: sneha,
        logo:i01,
    },
    {
        name: "Karan Prasad",
        position: "Program Manager",
        company: "Antler",
        linkedin: "https://www.linkedin.com/in/sunitaashoksingh/",
        desc: "Deepak Wadhwa is a prominent leader at TRADERsloop. Holding the position of Founder & CEO, he brings significant expertise and insights to his field.",
        img: karan,
        logo: antler_logo,
        
    },
    {
        name: "Deepika Jain",
        position: "Community and Partnerships",
        company: "Blume Ventures",
        linkedin: "https://www.linkedin.com/in/deepikadakuda/",
        desc: "Deepika Jain is a prominent leader at Blume Ventures. Holding the position of Community and Partnerships, she brings significant expertise and insights to her field.",
        img: deepika,
        logo:i2,
    },
    {
        name: "Puay Guan Goh",
        position: "Associate Professor",
        company: "NUS",
        linkedin: "https://www.linkedin.com/in/puay-guan-goh/",
        desc: "Puay Guan Goh is a prominent leader at NUS. Holding the position of Associate Professor, he brings significant expertise and insights to his field.",
        img: nus,
        logo:i5,
    },
    {
        name: "Zaheer Adenwala",
        position: "COO & Co-Founder",
        company: "Ketto",
        linkedin: "https://www.linkedin.com/in/zaheer-adenwala-0181421a/",
        desc: "Zaheer Adenwala is a prominent leader at Ketto. Holding the position of COO & Co-Founder, he brings significant expertise and insights to his field.",
        img: zaheer,
        logo:i4,
    },
    {
        name: "Anuj Singhal",
        position: "Managing Editor",
        company: "CNBC Awaaz",
        linkedin: "https://www.linkedin.com/in/singhalanuj/",
        desc: "Anuj Singhal is a prominent leader at CNBC Awaaz. Holding the position of Managing Editor, he brings significant expertise and insights to his field.",
        img: anuj,
        logo:i3,
    },
    {
        name: "Deepak Wadhwa",
        position: "Finance Content Creator",
        company: "TRADERsloop",
        linkedin: "https://www.linkedin.com/in/deepak-wadhwa-026228237/",
        desc: "Deepak Wadhwa is a prominent leader at TRADERsloop. Holding the position of Founder & CEO, he brings significant expertise and insights to his field.",
        img: deepak,
        
    },
    {
        name: "Hardip Singh",
        position: "COO",
        company: "Grew Energy",
        linkedin: "https://www.linkedin.com/in/hardip-singh-6416136/",
        desc: "Hardip Singh is a prominent leader at Grew Energy. Holding the position of COO, he brings significant expertise and insights to his field.",
        img: haridip,
        logo:i8,

    },
    
];

const Summits = () => {
    axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";
  const bgHaiJi = {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.6)), url(${bg})`,
      backgroundColor: "lightblue",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      width: "100vw",
      position: "absolute",
      top: "0",
      zIndex: "-1",
      backgroundAttachment: "fixed",
  };

  const sliderImages = [i0,i01,coin,i2,i3,i4,i5,i6,i7,i8,wadhwani_logo,antler_logo];

  const [formData, setFormData] = useState({
      name: '',
      mobile: '',
      email: '',
      position: '',
      company: '',
      college: '',
      city: ''
  });

  const handleChange = (e) => {
      setFormData({
          ...formData,
          [e.target.name]: e.target.value
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        await axios.post('https://techfest.org/api/register/', formData);
        alert('Registration successful!');
        
        // Reset the form data
        setFormData({
            name: '',
            mobile: '',
            email: '',
            position: '',
            company: '',
            college: '',
            city: ''
        });
    } catch (error) {
        console.error('Registration error:', error.response?.data);
        alert('Registration failed. Please try again.');
    }
};


  const renderSliderImages = () => {
      const repeatedImages = [...sliderImages, ...sliderImages, ...sliderImages];
      return repeatedImages.map((image, index) => (
          <img key={index} src={image} alt={`Slide ${index + 1}`} className={styles.sliderImage} />
      ));
  };

  return (
      <div className={styles.mainContainer} style={bgHaiJi}>
          <div className={styles.mainImg}>
              <div className={styles.imgleft}>
                  <img src={bleft} alt='' className={styles.bleft} />
              </div>
              <div className={styles.title}>
                  INTERNATIONAL INDUSTRY & FINTECH SUMMIT
              </div>
              <div className={styles.imgright}>
                  <img src={bright} alt='' className={styles.bright} />
              </div>
          </div>

          <div className={styles.sliderWrapper}>
              <div className={styles.sliderContainer}>
                  {renderSliderImages()}
              </div>
          </div>

          <div className={styles.speakerHeader}>SPEAKERS</div>
          <div className={styles.cardCont}>
              {speakersData.map((speaker, index) => (
                  <SummitCard
                      key={index}
                      logo={speaker.logo}
                      Post={speaker.position}
                      linkedin={speaker.linkedin}
                      Name={speaker.name}
                      desc={speaker.desc}
                      img={speaker.img}
                  />
              ))}
          </div>
          
          <div className={styles.registerForm}>
              <h2>Register Now</h2>
              <form onSubmit={handleSubmit}>
                  <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
                  <input type="tel" name="mobile" placeholder="Mobile" value={formData.mobile} onChange={handleChange} required />
                  <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                  <input type="text" name="position" placeholder="Position (Optional)" value={formData.position} onChange={handleChange} />
                  <input type="text" name="company" placeholder="Company (Optional)" value={formData.company} onChange={handleChange} />
                  <input type="text" name="college" placeholder="College (Optional)" value={formData.college} onChange={handleChange} />
                  <input type="text" name="city" placeholder="City (Optional)" value={formData.city} onChange={handleChange} />
                  <button type="submit">Register</button>
              </form>
              <button onClick={() => window.location.href = 'https://konfhub.com/techfest-summits'}>Pay Now</button>
          </div>
          <div className={styles2.Contactbox}>
            <div className={styles2.Contactbox1}>
              Sanjay Motwani<br></br>
              sanjay@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${7506024982}`)}>
                {7506024982}
              </div>
            </div>
            
            <div className={styles2.Contactbox1}>
              Basil Faruqui<br></br>
              basil@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${7317520804}`)}>
                {9399952997}
              </div>
            </div>
          </div>
      </div>
  );
};

export default Summits;
