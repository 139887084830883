import React from 'react';
import styles from './contactus.module.css';
import bg from '../../Home/assets/ContactUsbg.png';
import ContactCard from './contactcard.jsx';
import Ajay from './assets/Ajay_1.jpg';
import Chirag from './assets/Chirag.jpg';
import Rishab from './assets/Rishab1.jpg';
import Basil from './assets/Basil_1.jpg';
import MJ from './assets/Mj.jpg';
import Tarun from './assets/Tarunbby.png';
import Aleem from './assets/Aleem.jpg';
import Utkarsh from './assets/Utkarsh (2).jpg';
import Akshit from './assets/Akshit_1.jpg';
import Himanshu from './assets/Himanshu1.jpg';
import Ishan from './assets/Ishan_1.jpg';
import Aaditya from './assets/Paril.jpg';
import Sanjay from './assets/Sanjay_1.jpg';
import Arman from './assets/Arman_1.jpg';
import Divyansh from './assets/Divyansh_1.jpg';
import Ayush from './assets/Ayush_1.jpg';
import Ankit from './assets/Ankit_1.jpg';
import Vicky from './assets/Vicky_1.jpg';
import Divyanshu from './assets/Divyanshu1.jpg';
import Jaskaran from './assets/Jaskaran1.jpg';
import Hardik from './assets/Hardik1.jpg';
import Monank from './assets/Monank_1.jpg';
import Vyas from './assets/Vyas_01.jpg';
import Joshi from './assets/Joshi_1.jpg';

const ContactUs = () => {
  const bgHaiJi = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundColor: 'black',
    width: "100vw",
    position: "absolute",
    top: "0",
    zIndex: "-1",
    backgroundAttachment: "fixed",
  };

  return (
    <div>
      <div className={styles.contactus} style={bgHaiJi}>
        <div className={styles.heading}>Contact Us</div>
        <div className={styles.postsectionsoc}>
          <ContactCard img={Ajay} post='Overall Coordinator' name='Ajay Agrawal' email={<a href="mailto:ajay@techfest.org">ajay@techfest.org</a>} className={styles.top}/>
          <ContactCard img={Chirag} post='Overall Coordinator' name='Chirag Rao' email={<a href="mailto:chirag@techfest.org">chirag@techfest.org</a>} className={styles.top1}/>
        </div>
        <div className={styles.postsections}>
          <ContactCard img={Rishab} post='Competitions Manager' name='Rishabh Bhardwaj' phn={<a href="tel:+91 97982 73763">+91 97982 73763</a>} email={<a href="mailto:rishabh@techfest.org">rishabh@techfest.org</a>} />
          <ContactCard img={Basil} post='Competitions Manager' name='Basil Faruqui' phn={<a href="tel:+91 93999 52997">+91 93999 52997</a>} email={<a href="mailto:basil@techfest.org">basil@techfest.org</a>} />
          <ContactCard img={MJ} post='Competitions Manager' name='Mrutyunjaya Sahu' phn={<a href="tel:+91 96923 76527">+91 96923 76527</a>} email={<a href="mailto:mrutyunjaya@techfest.org">mrutyunjaya@techfest.org</a>} />
        </div>
        <div className={styles.postsections}>
          <ContactCard img={Tarun} post='Exhibitions Manager' name='Tarun Kumar' phn={<a href="tel:+91 75499 52736">+91 75499 52736</a>} email={<a href="mailto:tarun@techfest.org">tarun@techfest.org</a>} />
          <ContactCard img={Aleem} post='Exhibitions Manager' name='Aleem Rayeen' phn={<a href="tel:+91 70689 87053">+91 70689 87053</a>} email={<a href="mailto:aleem@techfest.org">aleem@techfest.org</a>} />
          <ContactCard img={Utkarsh} post='Exhibitions Manager' name='Utkarsh Jain' phn={<a href="tel:+91 62644 77005">+91 62644 77005</a>} email={<a href="mailto:utkarshjain@techfest.org">utkarshjain@techfest.org</a>} />
        </div>
        <div className={styles.postsections}>
          <ContactCard img={Akshit} post='Marketing Manager' name='Akshit Singhal' phn={<a href="tel:+91 88244 28661">+91 88244 28661</a>} email={<a href="mailto:akshit@techfest.org">akshit@techfest.org</a>} />
          <ContactCard img={Himanshu} post='Hospitality Manager' name='Himanshu Tanania' phn={<a href="tel:+91 97846 68915">+91 97846 68915</a>} email={<a href="mailto:himanshu@techfest.org">himanshu@techfest.org</a>} />
          <ContactCard img={Ishan} post='Robowars Manager' name='Ishan Patni' phn={<a href="tel:+91 62675 28479">+91 62675 28479</a>} email={<a href="mailto:ishan@techfest.org">ishan@techfest.org</a>} />
        </div>
        <div className={styles.postsections}>
          <ContactCard img={Aaditya} post='Media Manager' name='Aaditya Patil' phn={<a href="tel:+91 87885 07392">+91 87885 07392</a>} email={<a href="mailto:aaditya@techfest.org">aaditya@techfest.org</a>} />
          <ContactCard img={Sanjay} post='Marketing Manager' name='Sanjay Motwani' phn={<a href="tel:+91 75060 24982">+91 75060 24982</a>} email={<a href="mailto:sanjay@techfest.org">sanjay@techfest.org</a>} />
          <ContactCard img={Arman} post='Marketing Manager' name='Arman Khatri' phn={<a href="tel:+91 78629 32313">+91 78629 32313</a>} email={<a href="mailto:arman@techfest.org">arman@techfest.org</a>} />
        </div>
        <div className={styles.postsections}>
          <ContactCard img={Divyansh} post='Lectures Manager' name='Divyansh Ranjan' phn={<a href="tel:+91 83188 06303">+91 83188 06303</a>} email={<a href="mailto:ranjan@techfest.org">ranjan@techfest.org</a>} />
          <ContactCard img={Ayush} post='Ozone Manager' name='Ayush Bangar' phn={<a href="tel:+91 94629 20670">+91 94629 20670</a>} email={<a href="mailto:ayush@techfest.org">ayush@techfest.org</a>} />
          <ContactCard img={Ankit} post='Ozone Manager' name='Ankit Raj' phn={<a href="tel:+91 70616 82606">+91 70616 82606</a>} email={<a href="mailto:ankit@techfest.org">ankit@techfest.org</a>} />
        </div>
        <div className={styles.postsections}>
          <ContactCard img={Vicky} post='Publicity Manager' name='Vicky Yadav' phn={<a href="tel:+91 89761 60728">+91 89761 60728</a>} email={<a href="mailto:vicky@techfest.org">vicky@techfest.org</a>} />
          <ContactCard img={Divyanshu} post='Web Manager' name='Divyanshu Sahu' phn={<a href="tel:+91 95996 67349">+91 95996 67349</a>} email={<a href="mailto:divyanshu@techfest.org">divyanshu@techfest.org</a>} />
          <ContactCard img={Jaskaran} post='Creatives Manager' name='Jaskaran Singh' phn={<a href="tel:+91 75083 04402">+91 75083 04402</a>} email={<a href="mailto:jaskaran@techfest.org">jaskaran@techfest.org</a>} />
        </div>
        <div className={styles.postsections}>
          <ContactCard img={Hardik} post='Technoholix Manager' name='Hardik Gupta' phn={<a href="tel:+91 62604 47196">+91 62604 47196</a>} email={<a href="mailto:hardik@techfest.org">hardik@techfest.org</a>} />
          <ContactCard img={Monank} post='Technoholix Manager' name='Monank Vijayvargiya' phn={<a href="tel:+91 96446 76777">+91 96446 76777</a>} email={<a href="mailto:monank@techfest.org">monank@techfest.org</a>} />
        </div>
        <div className={styles.postsections}>
          <ContactCard img={Vyas} post='Infrastructure Manager' name='Aditya Vyas' phn={<a href="tel:+91 86024 50109">+91 86024 50109</a>} email={<a href="mailto:aditya.vyas@techfest.org">aditya.vyas@techfest.org</a>} className={styles.top}/>
          <ContactCard img={Joshi} post='Infrastructure Manager' name='Akash Joshi' phn={<a href="tel:+91 94250 95558">+91 94250 95558</a>} email={<a href="mailto:akash@techfest.org">akash@techfest.org</a>} className={styles.top}/>
        </div>
        <div className={styles.office}>
          <p><b>Techfest, IIT Bombay</b> <br />
          Techfest office, Old Sac, IIT Bombay, Powai, Mumbai - 400076
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
