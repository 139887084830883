import React from "react";
import { Link } from "react-router-dom";

import bg from "./img/ishan.jpg";
import Cards from "./cards";
import OurReach from "./ourreach";
import Carousel from "../../components/Carousel/carousel";
import "./robowars_animation.css";
import styles from "./robowars.module.css";
import styles2 from '../Home/home.module.css';

import irc1 from './img/irc.png';
import irc2 from './img/batalla.jpg';
import ircc3 from './img/orbit.png';
import irc3 from './img/logobotmakers.png';
import introbo from './img/robo.png';
import support from './img/support.png';
import intzo from './img/intzonalpart.png';
import CardHeading from '../Home/headingCard.jsx';
import irc4 from './img/irc4.png'
import irc5 from './img/irc5.png'
import Countdown from "./components/Countdown.jsx";


// import ImageOne from "./components/ImageOne";

const Robowars = () => {
  const bgHaiJi = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${bg})`, // Dark overlay + background image
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    width: "100vw",
    position: "absolute",
    top: "0",
    zIndex: "-1",
    backgroundAttachment: "fixed",
  };

  const deadline = new Date('November 8, 2024 23:59:59').getTime();

  return (
    <div className="competitions" style={bgHaiJi}>
      {/* <ImageOne /> */}
      {/* <div className={styles.sche}>
      SCHEDULE:- 8kg matches : 26th December ; 15kg, 30kg and 60kg knockouts : 27th December ; 15kg, 30kg and 60kg Finals : 28th December
      <br/> <br/>
      NOTE: Matches on 27th and 28th December will start from 8:30 AM SHARP
      </div> */}
      <div className={styles.introbo}><img src={introbo}></img></div>
      <div className={styles2.vid_desc_container}>
                <div>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/_aCQu35NA7M?si=myeK6p4-icxrkuOd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" className={styles2.video} allowfullscreen></iframe>
                </div>
                <div className={styles2.desc}>Techfest, IIT Bombay presents the International Robowars! India’s Largest Robot Combat Competition where 300+ participants from 20+ countries battle each other in front of a crowd of over 7000 people for the coveted Robowars Champion Title. In the ultimate battlefield 
          for destruction hungry robots, witness sparks fly in a captivating fusion of technology and combat
          as metal crushes metal in the most glorious battles, interspersed with
          magnificent artist performances for non-stop entertainment.</div>
              </div>
      {/* <div className={`${styles2.titSpn} ${styles2.homeTheme}`}>
        <div className={styles2.titSpn_rect1}>
          <div className={styles2.titSpn_rect2}>Resources</div>
        </div>
      </div> */}
      <Cards />

      <div className="contactus1">
      <CardHeading image={support}/>
          <div className="pkmkb">
            
            <img src={irc3} style={{width: '300px', top: '50px', position: 'relative'}}/>
          </div>
        </div>

        {/* <Countdown initialDays={22} initialHours={8} initialMinutes={0} initialSeconds={0} /> */}
        <Countdown deadline={deadline} />

      <div className="contactus1">
      <CardHeading image={intzo}/>
          <div className="pkmkb">
            <div className="irc">
              <img src={irc1} style={{width: '210px', height: '250px', top: '-50px', position: 'relative'}}/>
            </div>
            <div className="irc">
            <img src={irc2} />
            </div>
            <div className="irc">
            <img src={irc4} alt="irc4" />
            </div>
            <div className="irc">
              <img src={irc5} alt="irc5" />
            </div>
            {/* <img src={ircc3} /> */}
          </div>
        </div>

      <OurReach />
      
      
      <div className="checkThis">
        {/* <h1 style={{ marginTop: "10px", marginBottom: "40px" }}>
          CHECK THIS OUT
        </h1> */}
        {/* <div>
          <h2 style={{ marginBottom: "0px" }}>Exhibition</h2>
          <Carousel
            i1={i1}
            i2={i2}
            i3={i3}
            i4={i4}
            i5={i5}
            i8={i8}
            i6={i6}
            i7={i7}
            i9={i9}
            i10={i10}
          />
          <div className="difPage">
            <div className="check_rect1"></div>
            <div className="check_rect2">
              <Link to="/exhibitions">Explore</Link>
            </div>
          </div>
          <br />
        </div>
        <div style={{ marginTop: "40px", marginBottom: "0px" }}>
          <h2 className="gap">Lectures</h2>
          <Carousel
            i1={im1}
            i2={im2}
            i3={im3}
            i4={im4}
            i5={im5}
            i8={im8}
            i6={im6}
            i7={im7}
            i9={im9}
            i10={im10}
          />
          <div className="difPage">
            <div className="check_rect1"></div>
            <div className="check_rect2">
              <Link to="/lectures">Explore</Link>
            </div>
          </div>
        </div> */}
        <div className="contactus">
          {/* <h1 style={{ marginTop: "60px", marginBottom: "40px" }}>
            CONTACT US
          </h1> */}

          <div className="Contactbox">   
            <div className="Contactbox1">
              Ishan Patni<br></br>
              ishan@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${+916267528479}`)}>
                {+916267528479}
              </div>
            </div>
            <div className="Contactbox1">
              Utkarsh Jain<br></br>
              utkarshjain@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${+916264477005}`)}>
                {+916264477005}
              </div>
            </div>
          
                    
          </div>
        </div>
      </div>
    </div>
  );
};

export default Robowars;
