import React, { useState, useEffect } from "react";
import styles from "./compi_data.module.css";
// import Faq from "react-faq-component";
import "./x.css";
import Whatsapplink from "../../../../components/whatsapp/link";

const IDRL = () => {
  const [activeCity, setActiveCity] = useState("About");
  const [activeIndex, setActiveIndex] = useState(null);
  const openCity = (cityName) => {
    setActiveCity(cityName);
  };

  const handleClick = (index) => {
    if (activeIndex === index) {
      // If the clicked item is already open, close it
      setActiveIndex(null);
    } else {
      // If the clicked item is not open, open it and close any previously open item
      setActiveIndex(index);
    }
  };

  const styles1 = {
    bgColor: "none",
    titleTextColor: "white",
    rowTitleColor: "white",
    arrowColor: "white",
    rowContentColor: "white",
    marginBottom: "20px",
    maxWidth: "80%",
    margin: "auto",
    rowContentMarginBottom: "0",
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const lol = {
    objectFit: "scale-down",
    paddingRight: "8px",
  };
  const walink1 = "https://chat.whatsapp.com/EQnaJ50DFMnE6FBmCz6USt";
  const dslink1 = "https://chat.whatsapp.com/EQnaJ50DFMnE6FBmCz6USt";

  const gridStyle = windowWidth > 600 ? { display: 'grid', gridTemplateColumns: '20% 80%', gap: '20px' } : { display: 'flex', flexDirection: 'column', gap: '20px' };

  return (
    <div style={{padding:'24px'}}>
      <div style={{marginTop: '50px'}}></div>
      <div style={gridStyle}>
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', gap:'12px'}} >
          <a href='https://postimages.org/' target='_blank'><img src='https://i.postimg.cc/c1knqfvh/Group-248.png' border='0' alt='auv'style={{ width: '100%', height: 'auto' }}/></a>
          <h5>
            <div style={{color: "white", fontWeight:'bold', fontSize:'32px'}}> PRIZE INR 1,50,000 </div>
          </h5>
          <div className={styles.grouplink}>
          <div className={styles.wabutton}><Whatsapplink walink={walink1} dslink={dslink1} target="_blank" /></div>
          </div>
          <a href="/auv" style={{color: 'inherit'}}>
            <h5>
              {/* <div style={{color: '#00ffcc',border: '1px solid #00ffcc', padding: '10px', borderRadius: '5px', textAlign: 'center'}}>Problem Statement</div>   */}
            </h5>
          </a>
        </div>
        <div>
          <div className={styles.info}>
          </div>
          <div className={styles.info_data}>
            {/* <div></div> */}
            <h3>International Drone Racing League</h3>
            <h5 style={{fontWeight: 'bold'}}>Description</h5>
          <h5>The International FPV Drone Racing League is back at Techfest IIT Bombay! Get ready to witness one of the most thrilling events where drones will race through the skies with precision and speed. Registration is free and open to all.
 The event will be structured with qualifiers, semifinals, and finals, ensuring a fair and exciting competition, monitored by a team of judges, cameras, and timing systems. Practice sessions will take place on December 16, 2024, followed by the official race on December 17, 2024. This year's rules include strict guidelines for safety, equipment usage, and preparation. Don’t miss out on this elite drone racing competition!

For any queries, you can reach out to the organizing team..</h5>
          <br />

            
          </div>
        </div>

      </div>
      <div className='Contactbox'>
            <div className="Contactbox1">
            Yashvardhan<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${9993962830}`)}>
                {9993962830}
              </div>
            </div>
            
          </div>
    </div>
  );
};

export default IDRL;
