import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserAuth } from "../../../context/AuthContext";
import styles from "../../Robowars/robowars.module.css";
import style2 from './ift.module.css';
// import styles from './ift.module.css'

// Here is the code
const Cards = () => {
  const [data, setData] = useState([]);
  const { googleSignIn, user } = UserAuth();

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  const google_id = user?.email;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://techfest.org/api/ift/", {
          headers: {
            "X-Email": google_id,
          },
        });
        console.log(axios.defaults.headers.common);
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (google_id) {
      fetchData();
    }
  }, [google_id]);

  const card = () => {
    return data.map((data) => (
      <div key={data.id} className="col-lg-3 col-md-4 col-sm-6 col-12">
        <div className={styles.compi_card} id={style2.card} >
          <div className={styles.card_rect1}></div>
          <div className={styles.card_rect2}></div>
          <div className={styles.noReg}>
            <div className={styles.card_tag}></div>
            <div className={styles.card_imgo}>
              <img src={data.img} className={styles.card_img} alt={data.name} />
            </div>
            <div className={styles.card_title}>
              <h3>{data.category}</h3>
            </div>
          </div>
          <div>
            <div className={styles.card_regexp} id={style2.cardReg}>
              {/* Registration Logic */}
              {user === null ? (
                <div className={styles.card_reg}>
                  {/* Show Register Button if user is not logged in */}
                  <button onClick={handleGoogleSignIn} id={style2.regBtn}>Register</button>
                </div>
              ) : data.is_registered ? (
                <div className={styles.card_reg}>
                  {/* Show "Registered" if user is logged in and has registered */}
                  Registered
                </div>
              ) : data.closed ? (
                <div className={styles.card_reg}>
                  {/* Show "Closed" if registration is closed */}
                  Closed
                </div>
              ) : (
                <div className={styles.card_reg}>
                  {/* Show "Register" if user is logged in but hasn't registered and registration is open */}
                  <Link to={`${data.category}/register`}>Register</Link>

                </div>
              )}
              <div className={styles.card_exp}>
                <a href={data.statement} style={{ color: "#121927" }}> Explore </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className={styles.Technorion}>
      <div className="blackGradient-to-bottom" />
      <div className="technorion_cards">
        <div className="container" style={{ maxWidth: "90%" }}>
          <div className="row">{card()}</div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
