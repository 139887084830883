import React from 'react';
import styles from './SummitCard.module.css'
import linkedinlogo from './assets/linkedinlogo.png';

const SummitCard = ({Post, Name, desc, img, logo, linkedin}) => {
    
    const newWindow =() =>{
        window.open(linkedin , '_blank');
    }

    return (
        <>
         <div className={styles.Card}>
            <div className={styles.imgBox}>
               <div className={styles.imgDiv}>
                 <img className={styles.img} src={img} alt=''></img>
               </div>
               <div className={styles.namePost}>
                   <div className={styles.name}>{Name}</div>
                   <div className={styles.post}>{Post}</div>
                   {/* <div className={styles.company}>{company}</div> */}
                   <div className={styles.logo}>
                    <img src={logo} className={styles.logoimg} alt=''></img>
                   </div>
                   {/* <div className={styles.logo}>
                    <img src={logo} className={styles.logoimg} alt=''></img>
                   </div> */}
                <div className={styles.desc}>
                 {/* <div className={styles.logo}>
                  <img src={logo} className={styles.logoimg} alt=''></img>
                 </div> */}
               <div className={styles.linkedin} onClick={newWindow}>
                  {/* <img src={linkedinlogo}></img> */}
                  {/* LinkedIn */}
               </div>
            </div>
               </div>
            </div>
           {/* <div className={styles.desc}>
               <div className={styles.logo}>
                 <img src={logo} className={styles.logoimg} alt=''></img>
               </div>
               <a className={styles.linkedin} href='https://www.linkedin.com/in/smtgpt/'>
                  LinkedIn
               </a>
            </div> */}
         </div>
           
        </>
    )
}

export default SummitCard 