import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './components/Navbar/Navbar.jsx';
// import Footer from './components/Footer/Footer.jsx';
import { AuthContextProvider } from './context/AuthContext';
import Protected from './context/Protected.js';
// import usePageTracking from './context/usePageTracking.js'; 

import Home from './pages/Home/home.jsx';
import Exhi from './pages/Exhibitions/exhi.jsx';
import Lectures from './pages/Lectures/lectures.jsx';
import Compi from './pages/Compi/compi.jsx';
import CompiInternal from "./pages/Compi/Compi_Internal/index";
import Reg from "./pages/Compi/Compi_Internal/Reg";
import CreateTeam from "./pages/Compi/Compi_Internal/Teams/create_team";
import SingleParti from "./pages/Compi/Compi_Internal/Teams/single_parti";
import AddParti from "./pages/Compi/Compi_Internal/Teams/add_parti";
import ContactUs from './pages/AboutUs/ContactUs/contactus.jsx';
import Profile from './pages/Profile/profile.jsx';
import Legals from './pages/AboutUs/Legals/Legals.js';
import Acco from './pages/Accomodation/acco.jsx';
// import Gallery from './pages/AboutUs/Gallery/gallery.jsx'
import Bgmi from './pages/Esports/Bgmi.jsx';
import Valorant from './pages/Esports/Valorant.jsx';
import WorkReg from "./pages/Workshop/internal/reg";
import Loading from './pages/Loading/loading.jsx';
// import Footer from "./components/Footer/Footer";
import Media from "./pages/AboutUs/Media/index.jsx";
import Recognition from "./pages/AboutUs/recognition/Recognition";
import SMP from "./pages/AboutUs/SMP/smp.jsx";
// import Ift from './pages/IntFullThrottle/ift.jsx';
// import Sidebar from './components/Sidebar/sidebar.jsx';
import History from './pages/AboutUs/History/history.js'
import Footer from './components/Footer/Footer.jsx';
import ComingSoon from './components/ComingSoon/ComingSoon.jsx';
import Mun from './pages/Mun/Mun.jsx';
import Esports from './pages/Esports/Esports.jsx';
import Robowars from './pages/Robowars/robowars.jsx';
import RoboReg from './pages/Robowars/components/Reg.jsx';
import IFT from './pages/Events/ift/ift.jsx';
import IFTReg from './pages/Events/ift/reg.jsx';
import AUV from './pages/Compi/Compi_Internal/Competitions/AUV.jsx';
import IDRL from './pages/Compi/Compi_Internal/Competitions/Idrl.jsx';
import Summits from './pages/Summits/summits.jsx';
import IC from './pages/Innovation Challenge/ic.jsx';
import SocialInit from './pages/SocialInitiative/socialinit.jsx';

const Workshop = React.lazy(() => import("./pages/Workshop/home"));
// const Media = React.lazy(() => import("./pages/AboutUs/Media/index"));


const WorkshopExplore = React.lazy(() =>
  import("./pages/Workshop/internal/index")
);


function App() {
  // usePageTracking();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3500);
  }, []);
  

  return (
    <Router>
      <div className="App">
        
        <AuthContextProvider>
        <Navbar/>
        {/* <Sidebar/> */}
          <Routes>
          <Route path="/" element={isLoading ? <Loading /> : <Home />} />
            <Route
              path="/profile"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  {/* <Protected> */}
                  <Profile />
                  {/* </Protected> */}
                </React.Suspense>
              }
            />
          <Route
              path="/exhibitions"
              element={
                <React.Suspense fallback={<div>Loading...</div>}> 
                {/* <Protected> */}
                  <Exhi />
                {/* </Protected> */}
                </React.Suspense>
              }
            />
            <Route
              path="/lectures"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Lectures />
                </React.Suspense>
              }
              />
            <Route
              path="/competitions"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Compi />
                </React.Suspense>
              }
            />
            <Route path="/:compiName" element={<CompiInternal />} />
            <Route path="competitions/:compiName" element={<CompiInternal />} />
            <Route
              path="competitions/:compiName/register"
              element={
                <Protected>
                  <Reg />
                </Protected>
              }
            />
            <Route
              path="competitions/:compiName/createTeam"
              element={
                <Protected>
                  <CreateTeam />
                </Protected>
              }
            />
            <Route
              path="competitions/:compiName/singleparticipant"
              element={
                <Protected>
                  <SingleParti />
                </Protected>
              }
            />
            <Route
              path="competitions/:compiName/addparticipant"
              element={
                <Protected>
                  <AddParti />
                </Protected>
              }
            />
            <Route
              path="/contactus"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <ContactUs />
                </React.Suspense>
              }
            />

            {/* <Route
              path="aboutus/gallery"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Gallery />
                </React.Suspense>
              }
            /> */}
            
            <Route
              path="/aboutus/legals"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Legals />
                </React.Suspense>
              }

            />
            <Route
              path="/aboutus/media"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Media />
                </React.Suspense>
              }
            />
            <Route
              path="/ift"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <IFT />
                </React.Suspense>
              }
            />
             <Route
              path="/ift/:cardName/register"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Protected>
                    <IFTReg />
                  </Protected>
                </React.Suspense>
              }
            />
            <Route
              path="/aboutus/recognition"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Recognition />
                </React.Suspense>
              }
            />

            <Route
              path="/aboutus/smp"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <SMP />
                </React.Suspense>
              }
            />

            <Route
              path="/aboutus/history"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  {/* <History /> */}
                  <ComingSoon/>
                </React.Suspense>
              }
            />

            <Route

              path="/accommodation"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Acco />
                </React.Suspense>
              }

            />  

            <Route
              path="/workshops/"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Workshop />
                </React.Suspense>
              }
            />

            <Route
              path="/workshops/:cardName"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <WorkshopExplore />
                </React.Suspense>
              }
            />
            
            <Route
              path="workshops/:cardName/register"
              element={
                // <Protected>
                <WorkReg />
                // </Protected>
              }
            />

            
            <Route
              path="/auv"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  {/* <Ift/> */}<AUV />
                </React.Suspense>
              }
            />
            <Route
              path="/idrl"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  {/* <Ift/> */}<IDRL />
                </React.Suspense>
              }
            />
            <Route
              path="/summits"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  {/* <ComingSoon /> */}
                  <Summits />
                </React.Suspense>
              }
            />
            <Route
              path="/robowars"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Robowars/>
                </React.Suspense>
              }
            />
            <Route
              path="/robowars/:catName/register"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <RoboReg />
                </React.Suspense>
              }
            />
            <Route
              path="/tfmun"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  {/* <ComingSoon /> */}
                  <Mun />
                </React.Suspense>
              }
            />
            <Route
              path="/esports"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Esports />
                  {/* <ComingSoon /> */}
                </React.Suspense>
              }
            />
            <Route
              path="/innovationchallenge"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <IC/>
                </React.Suspense>
              }
            />
            <Route
              path="/bgmi"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Bgmi />
                </React.Suspense>
              }
            />
            <Route
              path="/valorant"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Valorant />
                </React.Suspense>
              }
            />
            <Route
              path="/socialinitiative"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <SocialInit />
                  {/* <ComingSoon /> */}
                </React.Suspense>
              }
            />
        
        {/* <Route path='/navbar' element={<Navbar1 />} /> */}
          </Routes>
          <Footer/>
          </AuthContextProvider>
      </div>
    </Router>
  );
}

export default App;
