import React, { useState } from 'react';
import styles from './compi_data.module.css';
import Faq from 'react-faq-component';
import './x.css';
import Whatsapplink from '../../../../components/whatsapp/link';

const WPC = () => {

    const [open, setOpen] = useState(false);

    const handleClickOpen = (openX) => {
        if(openX === open){
            setOpen(false);
        }
        else{
            setOpen(openX);
        }
    }

    const [openFaq, setOpenFaq] = useState(false);

    const OpenFaq = (index) => {
        setOpenFaq(prevOpenFaq => prevOpenFaq === index ? null : index);
    }


    const data = {
        rows: [
          {
            title: "What is the World Programming Championship (WPC)?",
            content:
              "The World Programming Championship is an annual global coding competition hosted by Techfest, IIT Bombay. It challenges participants to solve a series of algorithmic problems over a 12-hour period",
          },
          {
            title: "How to register?",
            content:
              "Follow this steps for registration : www.techfest.org > Competitions > WPC > Explore More -> Register > Fill all your details > You will be registered",
          },
          {
            title: "How many stages does the competition have?",
            content:
              "The competition is divided into 2 stages, detailed information about both stages can be found in the problem statement.",
          },
          {
            title: "What is the Registered Mail Id of a person?",
            content:
              "The Gmail Id which was used to Sign In is the registered mail Id. It is a unique identity of any participant. And will be used in adding team members to a team, joining a pre-existing team, availing accommodation, etc.",
          },
          {
            title: "Is it free to register for WPC?",
            content: "Yes, it is free to register.",
          },
          {
            title: "Is this competition team based?",
            content: "No, this competition allows individual participants to register and compete against other individuals.",
          },
          {
            title: "When and where is WPC 2024 taking place?",
            content:
              "WPC 2024 is scheduled for November 24th November, 2024 (Sunday), from 09:00 AM to 09:00 PM (12 hours). It's an online event, so you can participate from anywhere with a stable internet connection.",
          },
          {
            title: "What programming languages can I use?",
            content:
              "You can use a variety of popular programming languages, including but not limited to C++, Java, and Python.",
          },
          {
            title: "What types of problems can I expect?",
            content:
              "Problems will range from basic algorithms to complex optimization tasks. They are designed to test your problem-solving skills and coding efficiency across various difficulty levels.",
          },
          {
            title: "What do I need to participate?",
            content:
              "You'll need a computer with a stable internet connection, a supported web browser, and your preferred coding environment set up.",
          },
          {
            title: "What if I encounter technical issues during the competition?",
            content:
              "A technical support team will be available throughout the competition. Contact information and support procedures will be provided to all registered participants.",
          },
          {
            title: "Are there any resources to help me prepare for WPC 2024?",
            content:
              "Practicing on online coding platforms and participating in other coding competitions can help you prepare.",
          },
          {
            title: "Can I register for more than one competition?",
            content:
              "Yes, you can participate in more than one competition. However, it is recommended to focus on only one competition as there may be some chances of slot clash.",
          },
        ],
      };

    const styles1 = {
        bgColor: 'none',
        titleTextColor: '#fff',
        rowTitleColor: '#fff',
        arrowColor: '#fff',
        rowContentColor: '#fff',
        marginBottom: '20px',
        maxWidth: '80%',
        margin: 'auto',
        rowContentMarginBottom: '0',
      }
      const walink1 = "https://chat.whatsapp.com/CpZbjvvQyr6JNypfUB6rXy";
      const dslink1 = "https://chat.whatsapp.com/CpZbjvvQyr6JNypfUB6rXy";
    return (
        <div className={styles.mWholeData}>
        <div className={styles.grouplink}>
        <div className={styles.wabutton }><Whatsapplink walink=" https://chat.whatsapp.com/E4qvh1cNQy1A7f9bTNeAF4" dslink=" https://chat.whatsapp.com/E4qvh1cNQy1A7f9bTNeAF4 "target="_blank"/></div>
        </div>  
            <div>
                <div onClick={() => handleClickOpen('About')} className={`${styles.whatu} ${open === 'About' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatu_rect2}`}>About</div>
                </div>
                <div className={`${styles.whatd} ${open === 'About' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>Join the World Programming Challenge (WPC) at Techfest and compete with the world’s best coders for a shot at glory and the grand prize!</div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('Structure')} className={`${styles.whatu} ${open === 'Structure' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.str_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.str_rect2} ${styles.whatu_rect2}`}>Structure</div>
                </div>
                <div className={`${styles.whatd} ${open === 'Structure' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                      <ul>
                      <li>
                        Round 1: <br />
                        It will be a 12-hour long coding marathon on 20th November, 2024 where individuals compete against each-other to reach the WPC finals. 
                      </li>
                      <hr />
                      <li>
                        Round 2: <br />
                        The ultimate round, WPC Finals will be held during 17th - 19th December 2024. Participants clearing Round 1 will be invited for the WPC Finals.
                      </li>
                      </ul>
                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('Timeline')} className={`${styles.whatu} ${open === 'Timeline' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.time_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.time_rect2} ${styles.whatu_rect2}`}>Timeline</div>
                </div>
                <div className={`${styles.whatd} ${open === 'Timeline' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                    <ul>
                    <li>
                      Registration Deadline:
                      <br />
                      20th November
                    </li>
                    <hr />
                    <li>
                      Round 1:
                      <br />
                      20th November
                    </li>
                    <hr />
                    <li>
                      Round 2:
                      <br />
                      17th - 19th December
                    </li>
                    </ul>
                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('FAQs')} className={`${styles.whatu} ${open === 'FAQs' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.faq_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.faq_rect2} ${styles.whatu_rect2}`}>FAQs</div>
                </div>
                <div className={`${styles.whatd} ${open === 'FAQs' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                        <Faq data={data} styles={styles1}/>
                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('Rules')} className={`${styles.whatu} ${open === 'Rules' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.rule_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.rule_rect2} ${styles.whatu_rect2}`}>Rules</div>
                </div>
                <div className={`${styles.whatd} ${open === 'Rules' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                    1. Every individuals has to register online on the official Techfest website for the competition
                    <br/>2.  A Participant ID will be allocated to the participant on registration which shall be used for future references
                    <br/>3. The decision of the organizers or judges shall be treated as final and binding on all
                    <br/>4.  No responsibility will be held by Techfest, IIT Bombay for any late, lost or misdirected entries
                    <br/>5.  Note that at any point of time the latest information will be that which is on the website; however, registered participants will be informed through mail about any changes
                    <br/>6. All modes of official communication will be through the Techfest e-mail, participants are advised to keep track of all folders in their e-mail accounts
                    <br/>7. Participants have to bring their own laptops during the competition
                    <br/>8. The organisers reserve the right to change any or all of the above rules as they deem fit. Change in rules, if any, will be highlighted on the website and notified to the registered participant
                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('Contact')} className={`${styles.whatu} ${open === 'Contact' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.ctc_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.ctc_rect2} ${styles.whatu_rect2}`}>Contact</div>
                </div>
                <div className={`${styles.whatd} ${open === 'Contact' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                    Madhav Kotecha<br/>
                    <div className="col-3 contactNum" onClick={() => window.location.href = `tel:9313491381`}>+91 93134 91381</div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WPC;