import React, { useState } from 'react'
import styles from './Mun.module.css'
import munLogo from './assets/mun_logo.png'
import twmun from './assets/twmun.png'
import techfest from './assets/techfest.png'
import welcome from './assets/welcome.png'
// import unw from './assets/unw.jpg'
import unw from './assets/dummy.jpg'
import DISEC_school from './assets/DISEC_School Committee.jpg'
import UNHRC_school from './assets/UNHRC_School Committee.png'
import UNCSW_school from './assets/UNCSW_School committee.jpg'
import ECOFIN_CC from './assets/ECOFIN_College Committee.jpg'
import IP_CC from './assets/IP_College committee.jpg'
import ADHOC_CC from './assets/ADHOC_Flagship Committee.jpg'
import LokSabha_CC from './assets/Lok Sabha_College Committee.jpg'
import SPECPOL_CC from './assets/SPECPOL_College Committee.jpg'
import UNSC_CC from './assets/UNSC_College Committee.jpg'
import { modalview } from 'react-ga'
import SecyCard from './SecyCard'
import Navbar from './Navbar'
import { FaLocationDot } from "react-icons/fa6";
import { IoCallSharp } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";




const Mun = () => {
    const [ModalCon, setModalCon] = useState("This is for us");
    const [Modalactive, setModalactive] = useState(false);

    let modalview = (el) => {
        setModalactive(true);
        let tar = el.target.id;
        setModalCon(ModalContent[tar]);
    }

    let closeModal = () => {
        setModalactive(false);
    };

    const ModalContent = {
        DISEC: "Presenting our first school committee for Techfest WorldMUN 2024 - Disarmament and International Security Committee (DISEC). It is a leading global forum dedicated to addressing critical issues related to disarmament, international security, and non-proliferation. As a platform for dialogue and cooperation, DISEC plays a crucial role in promoting disarmament efforts, developing international norms, addressing emerging security threats, and addressing regional security concerns. Through its work, DISEC aims to create a more peaceful and secure world by reducing the risk of conflict and promoting disarmament.",
        UNHRC: "Presenting our third school committee for Techfest WorldMUN 2024 – The United Nations High Commissioner for Refugees (UNHCR). As the world's foremost organization dedicated to protecting the rights and well-being of refugees, UNHCR provides critical support to those forced to flee due to conflict, persecution, or disasters. They offer life-saving assistance, advocate for durable solutions, and strive to ensure that displaced individuals can rebuild their lives with dignity and hope. Join us as we delve into the vital work of UNHCR in creating a more compassionate and inclusive world.",
        UNCSW: "Introducing our second school committee for Techfest WorldMUN 2024 - The United Nations Commission on the Status of Women (UNCSW). It is a vital global platform for advancing gender equality and empowering women. UNCSW's key function is to develop and adopt international norms and standards related to gender equality and women's rights. These standards serve as guidelines for countries to implement policies and programs that promote gender equality. Through its work in setting standards, monitoring progress, shaping policies, and fostering cooperation, UNCSW contributes significantly to creating a more just and equitable world for all.",
        ECOFIN: "Introducing the third college committee for Techfest WorldMUN 2024 – the Economic and Financial Affairs Council (ECOFIN). This committee focuses on addressing global economic challenges and ensuring financial stability. ECOFIN tackles key issues such as international trade, economic development, and sustainable financial growth, fostering collaboration among member states to find innovative solutions.",
        IP: "Become the voice and lens of the future at Techfest WorldMUN. As an IP delegate, you'll investigate pressing global issues, conduct in-depth research, and craft compelling stories that inform and inspire through both words and images. Whether you're breaking news, capturing moments through photojournalism, or documenting events with fine art photography, your work will have a lasting impact. Join us to shape global narratives and share your unique perspective.",
        LOKSABHA: "Introducing the second college committee for Techfest WorldMUN 2024 – the Lok Sabha. This dynamic committee simulates the lower house of India’s Parliament, where delegates will engage in intense debates on pressing national issues. From policymaking to governance, the Lok Sabha committee offers a platform to discuss critical legislative matters and propose innovative solutions to challenges facing the nation.",
        SPECPOL: "Presenting the fourth college committee for Techfest WorldMUN 2024 – the Historic Special Political and Decolonization Committee (HSPECPOL). This historic committee addresses key issues such as decolonization, peacekeeping, and political cooperation. Focused on pivotal moments in history, HSPECPOL seeks to find diplomatic solutions to special political challenges and global security concerns, fostering international collaboration.",
        ADHOC: "The AD-HOC committee is a temporary body formed to address a specific issue, providing the guidance needed to resolve it. The details of the committee and its agenda will be revealed as the conference approaches. Focused on the individual abilities of delegates, this flagship committee of Techfest WorldMUN 2024 will provide intense debates and innovative solutions.",
        UNSC: 'Presenting the first college committee for Techfest WorldMUN 2024 – the United Nations Security Council (UNSC). This double delegation committee holds the critical responsibility of maintaining international peace and security. The UNSC leads in identifying threats to peace or acts of aggression and encourages parties in dispute to resolve their conflicts peacefully, recommending methods for negotiation and settlement.',
        WELCOMELETTER: "Despite the fact that time marches on, flaunting all of its unpredictable glory, Model United Nations Conferences have ensured our survival by connecting it to our capacity for change and providing us with a forum to voice our concerns without hindrance. You may have first become interested in MUN competitions by the allure of a competition in a conference room where each person is representing a country and discussing a global issue while attempting to find solutions to a problem. Even after years as members of the MUN Circuit we believe they leave a mark far more permanent than any award or certificate.MUNs have become a place for diplomacy, courtesy, friendships and collaboration. If you're looking for a place with a culture focused on growth, go no further than Techfest World Model United Nations 2024, where we've adopted an inclusive approach towards learning. Our Secretariat and the unparalleled Executive board will ensure that you will leave Techfest World MUN 2024 with far more information and connections than you had before you arrived.\n\nBhagyashree Birje (Advisory Board)    Krish Pajwani (Advisory Board)\n\nPratyush Dugar (Secretary General)    Sara Pimprikar (Secretary General)",
        TECHFEST: "Techfest is the Annual Science and Technology Festival of INDIAN INSTITUTE OF TECHNOLOIGY-BOMBAY, initiated in 1998 with a mission to promote technology, scientific thinking, and innovation. Now in its 27th Edition, Techfest has grown to be Asia’s Largest Science & Technology Festival with an astounding annual footfall of 1.75+ lakh, and an extensive network of over 3000 colleges, including more than 500 international universities. With a plethora of workshops, competitions, lectures, conferences, exhibitions, performances, concerts and various other events, Techfest is not just a college festival, it is an experience that you will cherish forever!",
        TWMUN: "Techfest World Model United Nations is finally back offline, on the grounds of INDIAN INSTITUTE OF TECHNOLOIGY-BOMBAY. It acts as a benchmark platform for advanced debate on the social, political and economic facets of the world at large. Through its forum of excellence, Techfest World Model United Nations has created an unparalleled legacy of robust debate involving members of the youth from across the world. This year, Techfest World Model United Nations promises to be bigger, bolder and better."
    };


    const [TabState, setTabState] = useState(false);
    const [RegCon, setRegCon] = useState("Register now and get the opportunity to learn, lead and become a part of the TWMUN legacy. School Committees are open to students up to 12th Grade, while College Committees are open for all")
    const toDelegation = () => {
        if (TabState) {
            setTabState(!TabState);
            setRegCon("Register now and get the opportunity to learn, lead and become a part of the TWMUN legacy.School Committees are open to students up to 12th Grade, while College Committees are open for all.")
        }
    }
    const toDelegates = () => {
        if (!TabState) {
            setTabState(!TabState);
            setRegCon("Register now and get the opportunity to learn, lead and become a part of the TWMUN legacy. A delegation consists of 6 or more members from one institution. School Committees are open to students up to 12th Grade, while College Committees will are open for all.")
        }
    }

    function delegateReg() {
        window.open('https://forms.gle/nNYPyHnjUWZstCbF8', '_blank');
    }

    function delegationReg() {
        window.open('https://forms.gle/EFrpFA7gczctCcYf6', '_blank');
    }

    // welcome letter modal



    return (
        <div className={styles.body}>
            {/* modal */}
            {Modalactive && <div className={styles.modal}>
                <div className={styles.information}>{`${ModalCon}`}</div>
                <button className={styles.closebtn} onClick={closeModal}>Close</button>
            </div>}

            {/* hero */}
            <div className={styles.hero}>
                <div className={styles.container}>
                    <img src={munLogo} alt="munLogo" />
                    <h1 className={styles.techfestHeader}>TECHFEST WORLD MUN</h1>
                    {/* <h2 styles="color: white;"><em>Beyond Hypocrisy</em></h2> */}
                </div>
            </div>
            {/* aboutus */}
            <div className={styles.aboutus}>
                <div className={styles.container}>
                    <h2 className={styles.header}>ABOUT US</h2>
                    <div className={styles.options}>
                        {/* 1 */}
                        <div className={styles.option}>
                            <div className={styles.pic}>
                                {/* <a href="#" data-toggle="modal" data-target="#abouttwmun"></a> */}
                                    <img
                                    src={twmun} alt="" onClick={modalview} id='TWMUN' />
                            </div>
                            <p className={styles.name}>TWMUN</p>
                        </div>
                        {/* 2 */}
                        <div className={styles.option}>
                            <div className={styles.pic}>
                                {/* <a href="#" data-toggle="modal" data-target="#abouttwmun"></a> */}
                                    <img
                                    src={techfest} class="circleImg" alt="" onClick={modalview} id='TECHFEST' />
                            </div>
                            <p className={styles.name}>TECHFEST</p>
                        </div>
                        {/* 3 */}
                        <div className={styles.option}>
                            <div className={styles.pic}>
                                {/* <a href="" data-toggle="modal" data-target="#abouttwmun"></a> */}
                                <img src={welcome} alt="" onClick={modalview} id='WELCOMELETTER' className={styles.welcomecon} />
                            </div>
                            <p className={styles.name}>WELCOME LETTER</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* video */}
            <div className={styles.video}>
                <div>
                    <iframe src="https://drive.google.com/file/d/1L5qDrkgPMQSVQKz02mZHBrp1KDE4NYJc/preview" width="600"
                        height="363"
                        allow="autoplay"
                        title="Google Drive Video"
                        frameBorder="10px"
                        allowFullScreen
                        style={{ borderRadius: "10px" }}
                        className={styles.vid1}></iframe>
                    {/* https://drive.google.com/uc?export=download&id=1L5qDrkgPMQSVQKz02mZHBrp1KDE4NYJc */}
                </div>
                <div>
                    <iframe src="https://drive.google.com/file/d/1BmeAxcYwExIxx98d9xi5Lwwm21eudjGB/preview" width="600"
                        height="363"
                        allow="autoplay"
                        title="Google Drive Video"
                        frameBorder="10px"
                        allowFullScreen
                        style={{ borderRadius: "10px" }} className={styles.vid2}></iframe>
                    {/* https://drive.google.com/file/d/1BmeAxcYwExIxx98d9xi5Lwwm21eudjGB/view?usp=drive_link */}
                </div>
            </div>

            {/* section */}
            <div className={styles.section}>
                {/* committees */}
                <div className={styles.committees}>
                    {/* {Modalactive && <div className={styles.modal}>
                 Helloo bhaiiii
                </div>} */}
                    <h2 className={styles.header}>COMMITTEES</h2>
                    <div className={styles.content}>
                        <div className={styles.school}>
                            <div>SCHOOL</div>
                            <div className={styles.boxes}>
                                <div className={styles.box}>
                                    <img src={DISEC_school} alt="" />
                                    <div className={styles.overlay}>
                                        {/* <div className={styles.overlayText}>hello</div> */}
                                        <div className={styles.readmore} onClick={modalview} id='DISEC'>Read More</div>
                                    </div>
                                </div>
                                <div className={styles.box}>
                                    <img src={UNHRC_school} alt="" />
                                    <div className={styles.overlay}>
                                        {/* <div className={styles.overlayText}>hello</div> */}
                                        <div className={styles.readmore} onClick={modalview} id='UNHRC'>Read More</div>
                                    </div>
                                </div>
                                <div className={styles.box}>
                                    <img src={UNCSW_school} alt="" />
                                    <div className={styles.overlay}>
                                        {/* <div className={styles.overlayText}>hello</div> */}
                                        <div className={styles.readmore} onClick={modalview} id='UNCSW'>Read More</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.college}>
                            <div className={styles.clgheader}>COLLEGE</div>
                            <div className={styles.boxesRow1}>
                                <div className={styles.box}>
                                    <img src={ECOFIN_CC} alt="" />
                                    <div className={styles.overlay}>
                                        {/* <div className={styles.overlayText}>hello</div> */}
                                        <div className={styles.readmore} onClick={modalview} id='ECOFIN'>Read More</div>
                                    </div>
                                </div>
                                <div className={styles.box}>
                                    <img src={IP_CC} alt="" />
                                    <div className={styles.overlay}>
                                        {/* <div className={styles.overlayText}>hello</div> */}
                                        <div className={styles.readmore} onClick={modalview} id='IP'>Read More</div>
                                    </div>
                                </div>
                                <div className={styles.box}>
                                    <img src={LokSabha_CC} alt="" />
                                    <div className={styles.overlay}>
                                        {/* <div className={styles.overlayText}>hello</div> */}
                                        <div className={styles.readmore} onClick={modalview} id='LOKSABHA'>Read More</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.boxesRow2}>
                                <div className={styles.box}>
                                    <img src={SPECPOL_CC} alt="" />
                                    <div className={styles.overlay}>
                                        {/* <div className={styles.overlayText}>hello</div> */}
                                        <div className={styles.readmore} onClick={modalview} id='SPECPOL'>Read More</div>
                                    </div>
                                </div>
                                <div className={styles.box}>
                                    <img src={ADHOC_CC} alt="" />
                                    <div className={styles.overlay}>
                                        {/* <div className={styles.overlayText}>hello</div> */}
                                        <div className={styles.readmore} onClick={modalview} id='ADHOC'>Read More</div>
                                    </div>
                                </div>
                                <div className={styles.box}>
                                    <img src={UNSC_CC} alt="" />
                                    <div className={styles.overlay}>
                                        {/* <div className={styles.overlayText}>hello</div> */}
                                        <div className={styles.readmore} onClick={modalview} id='UNSC'>Read More</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* registration */}
                {/* <div className={styles.registration}>
                <h2 className={styles.regheader}>REGISTRATION</h2>
                    <div className={styles.tabs}>
                        <div className={`${styles.delegates} ${!TabState ? styles.back : ''}`} onClick={toDelegation}>Delegate Registration</div>
                        <div className={`${styles.delegation} ${TabState ? styles.back : ''}`} onClick={toDelegates}>Delegation Registration</div>
                    </div>
                    <div className={styles.regContent}>
                        <div className={`${styles.delegatesCont} ${TabState ? styles.hide : ''}`}>
                            <div className={styles.regText}>Register now and get the opportunity to learn, lead and become a part of the TWMUN legacy.<br></br>School Committees are open to students up to 12th Grade, while College Committees are open for all.</div>
                            <div className={styles.regbtn} onClick={delegateReg}>REGISTER</div>
                        </div>
                        <div className={`${styles.delegationCont} ${!TabState ? styles.hide : ''}`}>Register now and get the opportunity to learn, lead and become a part of the TWMUN legacy.<br></br>A delegation consists of 6 or more members from one institution.<br></br>School Committees are open to students up to 12th Grade, while College Committees will are open for all.
                            <div className={styles.regbtn} onClick={delegationReg}>REGISTER</div>
                        </div>
                    </div>
                </div> */}

                <div className={styles.registration}>
                    <h2 className={styles.regheader}>REGISTRATION</h2>
                    <div className={styles.tabs}>
                        <div className={`${styles.delegates} ${TabState ? styles.back : styles.bottomBorder}`} onClick={toDelegation}>Delegate Registration</div>
                        <div className={`${styles.delegation} ${!TabState ? styles.back : styles.bottomBorder}`} onClick={toDelegates}>Delegation Registration</div>
                    </div>
                    <div className={styles.regbelow}>
                        <div className={styles.regContent} id='regContent'>
                            {`${RegCon}`}
                        </div>
                        <div className={styles.regbtn} onClick={TabState ? delegationReg : delegateReg}>REGISTER</div>
                    </div>
                </div>
                {/* secretariats */}
                <div className={styles.secretariats}>
                    <h2 className={styles.header}>SECRETARIAT</h2>
                    <div className={styles.secyCards}>
                        <div className={styles.box}>
                            <SecyCard Post="Director General" Email="hardikgupta@techfest.org" Name="Hardik Gupta" phn="+91 62604 47196" img={unw} />
                        </div>
                        <div className={styles.box}>
                            <SecyCard Post="Advisory Board" Email="bhagyashreebirje.work@gmail.com" Name="Bhagyashree Birje" phn="+91 81045 96316" img={unw} />
                        </div>
                        <div className={styles.box}>
                            <SecyCard Post="Advisory Board" Email="pajwanikrish123@gmail.com" Name="Krish Pajwani" phn="+91 72489 89506" img={unw} />
                        </div>
                        <div className={styles.box}>
                            <SecyCard Post="Secretary General" Email="pratyushdugar123@gmail.com" Name="Pratyush Dugar" phn="+91 77385 71273" img={unw} />
                        </div>
                        <div className={styles.box}>
                            <SecyCard Post="Secretary General" Email="sarapimprikar@gmail.com" Name="Sara Pimprikar" phn="+91 96890 76449" img={unw} />
                        </div>
                        <div className={styles.box}>
                            <SecyCard Post="Deputy Secretary General" Email="adamabbasmamode@gmail.com" Name="Adam Mamode" phn="+91 98202 12889" img={unw} />
                        </div>
                        <div className={styles.box}>
                            <SecyCard Post="Charge D'Affaires" Email="kritika0715@gmail.com" Name="Kritika Kumari" phn="+91 99692 73428" img={unw} />
                        </div>
                        <div className={styles.box}>
                            <SecyCard Post="Chief of Staff" Email="neha.arwade1@gmail.com" Name="Neha Arwade" phn="+91 98225 07434" img={unw} />
                        </div>
                        <div className={styles.box}>
                            <SecyCard Post="Chief Information Officer" Email="naiesshanayyar@gmail.com" Name="Naiessha Nayyar" phn="+91 98190 03998" img={unw} />
                        </div>
                        <div className={styles.box}>
                            <SecyCard Post="Director of Communications" Email="khush.trivedi005@gmail.com" Name="Khush Trivedi" phn="+91 96999 78484" img={unw} />
                        </div>
                        <div className={styles.box}>
                            <SecyCard Post="USG PR" Email="aasrithar@gmail.com" Name="Aasritha Rudararju" phn="+91 93944 18181" img={unw} />
                        </div>
                        <div className={styles.box}>
                            <SecyCard Post="USG PR" Email="anay.kulkarni@icloud.com" Name="Anay Kulkarni" phn="+91 87677 22015" img={unw} />
                        </div>
                    </div>
                </div>
                {/* faqs */}
                <div className={styles.faqs}>
                    <h2>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className={styles.questions}>
                        <div className={styles.question}>
                            <p className={styles.ques}>WHAT IS MODEL UNITED NATIONS?</p>
                            <p className={styles.ans}>Model United Nations (also Model UN or MUN) is an academic simulation of the United Nations that aims to educate and encourage participants to discuss about major issues concerning the world and the topics in international relations and diplomacy.</p>
                        </div>
                        <div className={styles.question}>
                            <p className={styles.ques}>WHAT ARE THE DATES OF TECHFEST WORLD MUN?</p>
                            <p className={styles.ans}>Techfest World MUN, IIT Bombay is a two day conference and will be held during Techfest 2024-25 from the 18th to the 19th of December, 2024.</p>
                        </div>
                        <div className={styles.question}>
                            <p className={styles.ques}>WHAT AWARDS WILL BE PROVIDED AT TWMUN 2024?</p>
                            <p className={styles.ans}>The awards will be presented at the discretion of the chairs of each committee and will include Best Delegate and High Commendations. A Best Delegation award will also be provided.</p>
                        </div>
                        <div className={styles.question}>
                            <p className={styles.ques}>WHO CAN REGISTER FOR TWMUN, INDIAN INSTITUTE OF TECHNOLOIGY-BOMBAY?</p>
                            <p className={styles.ans}>All students with a valid ID card of their high school or college can register for TWMUN, INDIAN INSTITUTE OF TECHNOLOIGY-BOMBAY. However, exceptional cases are sometimes made for recent graduates.</p>
                        </div>
                        <div className={styles.question}>
                            <p className={styles.ques}>WHEN WILL THE STUDY GUIDES BE RELEASED?</p>
                            <p className={styles.ans}>The study guides will be released approximately one month before the conference.</p>
                        </div>
                        <div className={styles.question}>
                            <p className={styles.ques}>WHAT IS THE VENUE?</p>
                            <p className={styles.ans}>Techfest World Model United Nations will be held on the campus of IIT Bombay.</p>
                        </div>
                        <a href='https://maps.app.goo.gl/s4khXqhRsSfNnuRJ6?g_st=iw'>Link for the same can be found here</a>
                    </div>
                </div>
                {/* contactus */}
                <div className={styles.contactus}>
                    <h2>CONTACT</h2>
                    <div className={styles.yoyo}>
                        <div className={styles.upar}>
                            <IoCallSharp className={styles.icon} />
                            <FaLocationDot className={styles.icon} />
                            <IoIosMail className={styles.icon} />
                        </div>
                        <div className={styles.niche}>
                            <p>+ 91 98225 07434</p>
                            <p>Techfest Office Students' Activity Center IIT Bombay, Powai, Mumbai Maharashtra, India - 400076</p>
                            <p>techfest.mun@gmail.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mun
