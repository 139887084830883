import React, { useState } from 'react';
import styles from './compi_data.module.css';
import Faq from 'react-faq-component';
import './x.css';
import Whatsapplink from '../../../../components/whatsapp/link';

const LQIdeathon = () => {

    const [open, setOpen] = useState(false);

    const handleClickOpen = (openX) => {
        if(openX === open){
            setOpen(false);
        }
        else{
            setOpen(openX);
        }
    }

    const [openFaq, setOpenFaq] = useState(false);

    const OpenFaq = (index) => {
        setOpenFaq(prevOpenFaq => prevOpenFaq === index ? null : index);
    }


    const data = {
        rows: [
          {
            title: "Why should I participate in the LQ Ideathon - Young Thinkers Challenge?",
            content: "The LQ Ideathon is a unique opportunity for young minds to solve real-world problems and contribute to building a better future. By participating, you’ll enhance your problem-solving, critical thinking, and creativity, while getting a chance to present your innovative ideas at TechFest IIT Bombay, Mumbai!"
          },
          {
            title: "How do I register for the LQ Ideathon?",
            content: "You need to be invited to participate. The invitation will be sent to your school. Once you receive the invitation, simply follow the instructions. Make sure to complete your registration in time to ensure your spot in the competition."
          },
          {
            title: "Is it free to register for the LQ Ideathon?",
            content: "Yes, registration for the LQ Ideathon is completely free of charge."
          },
          {
            title: "Can I have more than one submission?",
            content: "No, each participant or team can only submit one solution for the Ideation Round. Multiple submissions are not allowed."
          },
          {
            title: "How many stages does the competition have?",
            content: "The LQ Ideathon has two stages: ○ Stage 1: The Ideation Round (online submission of the problem solution). ○ Stage 2: The Presentation Round (finalists present their solutions in-person at IIT Bombay, Mumbai)."
          },
          {
            title: "Can I participate as part of a team?",
            content: "No, participants must register individually. Team registrations are not permitted."
          },
          {
            title: "What happens if I qualify for the second stage?",
            content: "If you qualify for Stage 2, you will be invited to present your solution at the IIT Bombay campus in Mumbai."
          },
          {
            title: "Will travel and accommodation be provided for the finalists?",
            content: "No, travel and accommodation will not be provided. It is the responsibility of the finalists to make their own arrangements for the final round in Mumbai."
          },
          {
            title: "What are the age categories for the competition?",
            content: "There are 3 age categories: Category A - Grade 5 and 6, Category B - Grade 7 and 8, Category C - Grade 9 and 10. Each category will be judged independently"
          },
          
        ],
      };

    const styles1 = {
        bgColor: 'none',
        titleTextColor: '#fff',
        rowTitleColor: '#fff',
        arrowColor: '#fff',
        rowContentColor: '#fff',
        marginBottom: '20px',
        maxWidth: '80%',
        margin: 'auto',
        rowContentMarginBottom: '0',
      }
      const walink1 = "https://chat.whatsapp.com/CpZbjvvQyr6JNypfUB6rXy";
      const dslink1 = "https://chat.whatsapp.com/CpZbjvvQyr6JNypfUB6rXy";
    return (
        <div className={styles.mWholeData}>
        <div className={styles.grouplink}>
        <div className={styles.wabutton }><Whatsapplink walink={walink1} dslink={dslink1} target="_blank"/></div>
        </div>  
            <div>
                <div onClick={() => handleClickOpen('About')} className={`${styles.whatu} ${open === 'About' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatu_rect2}`}>About</div>
                </div>
                <div className={`${styles.whatd} ${open === 'About' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>The LQ Ideathon - Young Thinkers Challenge is a platform designed to inspire
young minds to develop creative, actionable solutions for today’s pressing
challenges to shape a better tomorrow. This two-stage competition invites
children to foster critical thinking, problem-solving, and adaptability in a structured
environment. Participants tackle real-world problems and bring their innovative
ideas to life through this inspiring challenge.</div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('Structure')} className={`${styles.whatu} ${open === 'Structure' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.str_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.str_rect2} ${styles.whatu_rect2}`}>Structure</div>
                </div>
                <div className={`${styles.whatd} ${open === 'Structure' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                    <ul>
                <li>
                  Stage 1: Ideation Round <br />
                  Registered participants receive a problem statement via email.
This stage is entirely online, where participants develop their ideas and submit
their solutions.
                </li>
                <hr />
                <li>
                  Stage 2: Presentation Round <br />
                  Top performers from the Ideation Round advance to this in-person final round.
Finalists will work on their detailed idea and present their solutions at the
prestigious IIT Bombay Campus from Dec 17 to Dec 19
An expert panel of judges will evaluate the presentations, providing participants
with feedback and recognition.
                </li>
              </ul>
                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('Timeline')} className={`${styles.whatu} ${open === 'Timeline' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.time_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.time_rect2} ${styles.whatu_rect2}`}>Timeline</div>
                </div>
                <div className={`${styles.whatd} ${open === 'Timeline' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                    <li>
      Ideation Round:
      <br />
      Registered students will receive a problem statement via email
November 15 onwards, which they have to complete and submit by November
26.
    </li>
    <hr />
    <li>
      Results Announcement:
      <br />
      1st December
    </li>
<hr/>
    <li>
      Final Presentation and Demonstration:
      <br />
      17th-19th December
    </li>
                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('FAQs')} className={`${styles.whatu} ${open === 'FAQs' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.faq_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.faq_rect2} ${styles.whatu_rect2}`}>FAQs</div>
                </div>
                <div className={`${styles.whatd} ${open === 'FAQs' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                        <Faq data={data} styles={styles1}/>
                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('Rules')} className={`${styles.whatu} ${open === 'Rules' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.rule_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.rule_rect2} ${styles.whatu_rect2}`}>Rules</div>
                </div>
                <div className={`${styles.whatd} ${open === 'Rules' ? styles.whatdOpen : ''}`}>
                    <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div>
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                    1. LQ Ideathon, its organizers, or partners will not be held responsible for any late,
lost, or misdirected entries. Participants are responsible for ensuring that their
submissions are submitted on time and in the correct format.
            <br/>2. All submissions for the Ideation Round must be original and adhere to the word
and format guidelines provided. Any submission found to be plagiarized or
infringing on the intellectual property of others will be disqualified.
            <br/>3. The decisions made by the organizers or judges are final and binding for all
            participants. Any appeals or disputes will not be entertained.
            <br/>4. Official communication will occur only through the LQ Ideathon email.
Participants should ensure that they regularly check all folders (including spam or
junk folders) in their email accounts to stay updated on important
announcements.
            <br/>5. Finalists who qualify for the in-person Presentation Round at IIT Bombay must be
present on the scheduled dates. Travel and accommodation arrangements, if
applicable, will be the responsibility of the finalist team members.
                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleClickOpen('Contact')} className={`${styles.whatu} ${open === 'Contact' ? styles.whatuOpen : ''}`}>
                    <div className={`${styles.ctc_rect1} ${styles.whatu_rect1}`}></div>
                    <div className={`${styles.ctc_rect2} ${styles.whatu_rect2}`}>Contact</div>
                </div>
                <div className={`${styles.whatd} ${open === 'Contact' ? styles.whatdOpen : ''}`}>
                    {/* <div className={`${styles.abt_rect1} ${styles.whatd_rect1}`}></div> */}
                    <div className={`${styles.abt_rect2} ${styles.whatd_rect2}`}>
                    Email: ideathon@logiqids.com <br/>
                    Jay Ajinkya<br/>
                    <div className="col-3 contactMail" onClick={() => window.location.href = `mailto:jayajinkya.techfest@gmail.com`}>jayajinkya.techfest@gmail.com</div>

                    
                    {/* </div> */}
                    
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default LQIdeathon;