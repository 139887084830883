import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import styles from "./compi.module.css";
import indiamap from "./static/img/map.png";
import trophy from './static/img/trophy.png';
import grid from './static/img/ground.png';
import compi from '../Home/home.module.css';
import lines from './static/img/lines.png';
import wires from './static/img/wires.png';
import dots1 from '../Home/assets/img1_bubbles.png';
import dots2 from '../Home/assets/img2_bubbles.png';
import compihead from './static/img/compihead.png';
import technorion from './static/img/technorion.png';
import ideates from './static/img/ideates.png';
import CompiText from './assets/year_pallete (3).svg';
import AI from './static/img/AI.png';

// Images
import dot from "./static/card/dot.png";
import chand from "./static/card/chand.png";
import espark from "./static/card/espark.png";
import fspark from "./static/card/fspark.png";
import WhatsappShareButton from "../../components/share/whatsapp";
import dots from '../Home/assets/dots.png';
import Pulse1 from '../Home/assets/Pulse1.png'
import parallax from '../Workshop/components/parallax.module.css'
import ParticlesComponent from "../Home/particles";
import IntlCompi from "./static/img/intlcompi.png";

const Technorion = () => {
  const [data, setData] = useState([]);
  const { googleSignIn, user } = UserAuth();

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  const google_id = user?.email;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://techfest.org/api/compi_card/", {
          headers: {
            "X-Email": google_id,
          },
        });
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [google_id]);

  const renderCards = (genre) => {
    const filteredData = data.filter((item) => item.genre === genre);

  const ExploreSelection = (name) => {
    if(name === "auv" ){
      return "/auv"
    }
    else if(name === "ift"){
      return "/ift";
    }
    else if(name === "int'l drone racing"){
      return "/idrl";
    }
    else if(name === "SEED Hackathon"){
      return "https://event.seedglobaleducation.com/hackathon-interest/?utm_campaign=SEED%20Program%20Marketing&utm_source=SEED130";
    }
    else{
      return name;
    }
  };

  const RegisterSelector = (name) => {
    if(name === "ift"){
      return "/ift";
    }
    else if(name === "SEED Hackathon"){
      return "https://event.seedglobaleducation.com/hackathon-interest/?utm_campaign=SEED%20Program%20Marketing&utm_source=SEED130";
    }
    else {
      return `${name}/register`;
    }
  }
    return filteredData.map((data) => (
      <div key={data.id} className={styles.cardContainer}>
        <div className={styles.compi_card}>
          
          <div className={styles.noReg}>
            <Link to={data.name}></Link>
            <div className={styles.card_tag}>
              <h3 className={styles.card_tag}>{data.tag}</h3>
            </div>
            
            <div className={styles.card_img_container}>
    <img src={data.img} className={styles.card_img} alt={data.name} />
  </div>

            <div className={styles.card_title}>
            {/* <div className={styles.card_taggy}>{data.tag}</div> */}
              <h3>{data.name}</h3>
              
              <div className={styles.card_sustitle}>Prize INR {data.prize} </div>
              <div className={styles.card_desc}>{data.desc}</div>
              {/* <div className={styles.card_headtitle}>{data.name}</div> */}
              
            </div>
            {/* <div className={styles.card_post}>
              <div className={styles.card_share}>
                <div className={styles.share_border}>
                  
                </div>
              </div>
            </div> */}
          </div>
          
          <div>
            <div className={styles.card_regexp}>
              {user === null ? (
                <div className={styles.card_reg}>
                  <button onClick={handleGoogleSignIn}>Register</button>
                </div>
              ) : data.closed ? (
                <div className={styles.card_reg}>Closed</div>
              ) : data.is_registered ? (
                <div className={styles.card_reg}>Registered</div>
              ) : (
                <div className={styles.card_reg}>
                  {data.name === "robocap league" ? (
                    <a href="https://www.robocapleague.com/apply" target="_blank" rel="noopener noreferrer">
                      Register
                    </a>
                  ) : (
                    <Link to={RegisterSelector(data.name)}>Register</Link>
                  )}
                </div>
              )}
              <div className={styles.card_exp}>
                <Link to={ExploreSelection(data.name)}>Explore</Link>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className={styles.Technorion}>
      {/* <div className="blackGradient-to-bottom" /> */}
      <div className={styles.firstView}>
            <div className={styles.left}>
            <ParticlesComponent/>
              {/* <img src={Pulse1} alt="pulse" className={compi.Pulse1} /> */}
                {/* <div className={compi.timtim} style={{
                        top: '6.5%',
                        left: '19%' }}
                ></div>
                <div className={compi.timtim} style={{
                        top: '10.2%',
                        left: '48%' }}
                ></div>
                <div className={compi.timtim} style={{
                        top: '12.9%',
                        left: '73%' }}
                ></div>
                <div className={compi.timtim} style={{
                        top: '18.4%',
                        left: '58%' }}
                ></div>
                 <div className={compi.timtim} style={{
                        top: '21%',
                        left: '91.5%' }}
                ></div>
                <div className={compi.timtim} style={{
                        top: '24.5%',
                        left: '96%' }}
                ></div>
                <div className={compi.timtim} style={{
                        top: '29.6%',
                        left: '65.5%' }}
                ></div>
                <div className={compi.timtim} style={{
                        top: '31.7%',
                        left: '50.5%' }}
                ></div>
                <div className={compi.timtim} style={{
                        top: '33.1%',
                        left: '48%' }}
                ></div>
                <div className={compi.timtim} style={{
                        top: '34.3%',
                        left: '69%' }}
                ></div> */}
            </div>
            <div className={styles.middle}>
                <div className={styles.linesimage}>
                    <img src={lines} alt="no image"></img>
                </div>
                <div className={styles.wiresimage}>
                    <img src={wires} alt="no image"></img>
                </div>
                <div className={styles.trophyimage}>
                    <img src={trophy} alt="no image"></img>
                </div>
                <div className={styles.rightimage}>
                <img src={dots1} alt="pulse" className={styles.dots1} />
                <img src={dots2} alt="pulse" className={styles.dots2} />
                </div>
                <div className={styles.gridimage}>
                    <img src={grid} alt="no image"></img>
                </div>
                {/* <div className={styles.compiTextdiv}>
                 <img src={CompiText} alt=""></img>
                </div> */}
                
            </div>
            <div className={parallax.scrollPrompt} id="js_scrollPrompt">
              <div className={parallax.scrollPromptShape}></div>
      </div>
            {/* <div className={compi.right}>
            <img src={dots} alt="pulse" className={compi.Pulse2} />
            </div> */}
        </div>
      <div className={styles.technorion_cards}>
          
      <div className={styles.zonalhead}>
            <img src={AI}></img>
            {/* <h1>Competitions</h1> */}
          </div>
        <div className={styles.zonals}>
          
          <div className={styles.cardGrid}>
          {renderCards("3")}
          </div>
          </div>

          <div className={styles.zonalhead}>
          <img src={compihead}></img>
          </div>
        <div className={styles.zonals}>
          <div className={styles.cardGrid}>
          {renderCards("2")}
          </div>
          </div>
          <div className={styles.zonalhead}>
        <img src={IntlCompi}></img>
        </div>
      <div className={styles.cardGrid}>
          {/* {renderCards("1")} */}
          {/* {renderCards("2")} */}
          {/* {renderCards("3")} */}
          {renderCards("4")}
        </div>

        <div className={styles.zonalhead}>
        <img src={technorion}></img>
        </div>
        <div className={styles.zonals}>
          {/* <div className={styles.techno}>

          </div> */}
          <div className={styles.cardGrid}>
          {renderCards("1")}
          </div>
          </div>
        
      </div>

      <br/><br/>
      
      {/* <h1>TECHNORION</h1> */}
      <div className={styles.desc}>
        <div className={styles.desc_cont}>
          <p className={styles.descT}>
           With an aim to promote creative, innovative and technical skills amongst the youth, Techfest is coming to the cities near you through Technorion, our Zonal competitions. We believe everyone should get an equal opportunity to showcase their talents and distance should not create a hindrance. With this underlying spirit, we announce the following Technorion Zonal cities.
          </p>
          <div className={styles.textContainer}>
            <table border="1">
              <tr>
                <th>College</th>
                <th>CITY</th>
                <th>DATE</th>
              </tr>
              
              <tr>
                <td>Indian Institute of Technology Bombay</td>
                <td>Mumbai</td>
                <td>6/10/24</td>
              </tr>
              <tr>
                <td>Raj Institute of Coding and Robotics</td>
                <td>Bhopal</td>
                <td>6/10/24</td>
              </tr>
              <tr>
                <td>Priyadarshini College of Engineering</td>
                <td>Nagpur</td>
                <td>19/10/24</td>
              </tr>
              <tr>
                <td>Lords Institute of Engineering & Technology</td>
                <td>Hyderabad</td>
                <td>20/10/24</td>
              </tr>
              <tr>
                <td>LNM Institute of Information Technology</td>
                <td>Jaipur</td>
                <td>20/10/24</td>
              </tr>
              <tr>
                <td>Global Academy of Technology</td>
                <td>Bangalore</td>
                <td>26/10/24</td>
              </tr>
              <tr>
                <td>Punjab Engineering College</td>
                <td>Chandigarh</td>
                <td>8/11/24</td>
              </tr>
            </table>
          </div>
        </div>
        {/* <div className={styles.mapContainer}>
          <img src={indiamap} alt="india" className={styles.descimage} />
        </div> */}
      </div>
    </div>
  );
};

export default Technorion;
